var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MutateScheduleMolecule', {
    attrs: {
      "title": _vm.$t('titles.editSchedule'),
      "assetsByRecipient": _vm.assetsByRecipient,
      "schedule": _vm.schedule
    },
    on: {
      "cancel": function cancel($event) {
        return _vm.$emit('close');
      },
      "confirm": _vm.updateSchedule
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }