<template>
  <EditScheduleOverlayStyled>
    <EditScheduleOrganism @close="$emit('close')" :calendarId="args.calendarId" :scheduleId="args.scheduleId" @updated="handleUpdated" />
  </EditScheduleOverlayStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import EditScheduleOrganism from '@/pages/PlanningApp/Organisms/EditScheduleOrganism.vue'

const EditScheduleOverlayStyled = styled('div')`
  width: 100%;
  height: calc(100% - 50px - 1rem);
  max-width: 800px;
  padding: 1rem;
  padding-top: 50px;
  .modal {
    height: 100%;
  }
`

export default {
  components: {
    EditScheduleOverlayStyled,
    EditScheduleOrganism,
  },
  props: {
    args: {
      type: Object,
    },
  },
  methods: {
    handleUpdated(payload) {
      const scheduleId = payload?.data?.updateSchedule?.id
      this.args?.updatedCallback?.({ scheduleId })
    },
  },
}
</script>
