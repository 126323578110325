<template>
  <MutateScheduleMolecule
    :title="$t('titles.editSchedule')"
    :assetsByRecipient="assetsByRecipient"
    :schedule="schedule"
    @cancel="$emit('close')"
    @confirm="updateSchedule"
  />
</template>

<script>
import { MutateScheduleMolecule } from '@common/components'
import { DateTime } from 'luxon'

import MY_CALENDARS_LIST_QUERY from '#/graphql/calendar/myCalendarsWithMinimalSchedules.gql'
import CALENDAR_QUERY from '#/graphql/calendar/myCalendarMinimal.gql'
import CALENDAR_SHOW_QUERY from '#/graphql/calendar/show.gql'
import SCHEDULE_QUERY from '#/graphql/calendar/schedule/show.gql'
import UPDATE_SCHEDULE_MUTATION from '#/graphql/calendar/schedule/update.gql'
import ASSETS_BY_RECIPIENT_QUERY from '#/graphql/operations/assets/_assetsByRecipient.gql'

export default {
  props: {
    calendarId: {
      type: String,
      required: true,
    },
    scheduleId: {
      type: String,
      required: true,
    },
  },
  components: {
    MutateScheduleMolecule,
  },
  data() {
    return {
      assetsByRecipient: [],
      calendar: null,
      schedule: null,
    }
  },
  computed: {
    selectedSchedule() {
      return this.schedule
    },
    firstRecurrenceId() {
      return this.selectedSchedule?.recurrences[0]?.id
    },
  },
  methods: {
    getDay(schedule) {
      if (!schedule.scheduleStartDateTime || !schedule.timezone) {
        return null
      }
      return DateTime.fromJSDate(schedule.scheduleStartDateTime).setZone(schedule.timezone)
    },
    getStart(schedule) {
      const day = this.getDay(schedule)
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      return DateTime.fromFormat(`${dayStr} ${schedule.startTime}`, 'yyyy-LL-dd HH:mm:ss')
    },
    getEnd(schedule) {
      const day = this.getDay(schedule)
      const start = this.getStart(schedule)
      if (day === null) {
        return null
      }
      const dayStr = day.toFormat(`yyyy-LL-dd`)
      const end = DateTime.fromFormat(`${dayStr} ${schedule.endTime}`, 'yyyy-LL-dd HH:mm:ss')
      if (end.toMillis() <= start.toMillis()) {
        return end.plus({ days: 1 })
      }
      return end
    },
    getDuration(schedule) {
      const start = this.getStart(schedule)
      const end = this.getEnd(schedule)
      if (!start || !end) {
        return null
      }
      const duration = end.diff(start)
      if (duration.isLuxonDuration) {
        return duration
      }
      return null
    },
    getRRule(schedule) {
      const scheduleStartDateTime = DateTime.fromJSDate(schedule.scheduleStartDateTime).setZone(schedule.timezone, {
        keepLocalTime: true,
      })
      const start = this.getStart(schedule)
      const duration = this.getDuration(schedule)
      if (scheduleStartDateTime.invalid || !duration || !start) {
        return null
      }
      const durationInMillis = duration.toMillis()
      if (durationInMillis < 1) {
        return null
      }
      const rrule = {
        frequency: schedule.frequency,
        interval: parseInt(schedule.interval),
        start: scheduleStartDateTime,
        duration: durationInMillis,
        byDayOfWeek: schedule.byDayOfWeek,
        byHourOfDay: [start.hour],
        byMinuteOfHour: [start.minute],
        bySecondOfMinute: [start.second],
      }
      return rrule
    },
    async updateSchedule(schedule) {
      const recurrenceData = {}
      const rrule = this.getRRule(schedule)
      Object.keys(rrule).map(key => {
        if (rrule[key]?.isLuxonDateTime) {
          recurrenceData[key] = {
            set: rrule[key].toISO(),
          }
        } else {
          recurrenceData[key] = {
            set: rrule[key],
          }
        }
      })

      const createPerformanceGoals = schedule.performanceGoals
        .filter(p => !p.id)
        .map(({ name, assetFilterIds, performanceGoalRules }) => {
          const createRules = performanceGoalRules.map(({ assetDimensionId, min, max, threshold, thresholdTypeEnum }) => ({
            assetDimensionId,
            min,
            max,
            threshold,
            thresholdTypeEnum,
          }))
          return {
            name,
            assetFilterIds: {
              set: assetFilterIds,
            },
            performanceGoalRules: {
              create: createRules,
            },
          }
        })
      const deletePerformanceGoals = this.selectedSchedule?.performanceGoals
        .filter(p => !schedule.performanceGoals.find(s => s.id === p.id))
        .map(m => ({ id: m.id }))

      const data = {
        type: {
          set: schedule.type,
        },
        name: {
          set: schedule.name,
        },
        createShiftReport: {
          set: schedule.createShiftReport,
        },
        assignedAssets: {
          create: schedule.assignedAssets
            .filter(f => !this.selectedSchedule?.assignedAssets?.find(a => f.assetId === a.assetId))
            .map(({ assetId, assetType }) => ({
              assetId,
              assetType,
            })),
          delete: this.selectedSchedule?.assignedAssets
            .filter(f => !schedule.assignedAssets.find(a => f.assetId === a.assetId))
            .map(m => ({ id: m.id })),
        },
        performanceGoals: {
          create: createPerformanceGoals,
          delete: deletePerformanceGoals,
        },
      }

      if (this.selectedSchedule?.useTimezoneInRSchedule) {
        data.timezone = {
          set: schedule.timezone,
        }
        data.recurrences = {
          update: {
            data: recurrenceData,
            where: {
              id: this.firstRecurrenceId,
            },
          },
        }
      }

      if (schedule.assignedAssets.length === 0) {
        data.assignedAssets = {
          delete: this.selectedSchedule?.assignedAssets.map(m => ({ id: m.id })),
        }
      }

      try {
        const res = await this.$apollo.mutate({
          mutation: UPDATE_SCHEDULE_MUTATION,
          variables: {
            data,
            where: {
              id: this.selectedSchedule?.id,
            },
          },
          update: async (store, { data }) => {
            const newSchedule = data.updateSchedule
            const { myCalendars } = store.readQuery({
              query: MY_CALENDARS_LIST_QUERY,
            })
            const calendar = myCalendars.find(c => c.id === this.calendar.id)
            const index = calendar.schedules.findIndex(s => s.id === newSchedule.id)
            calendar.schedules[index] = {
              id: newSchedule.id,
              name: newSchedule.name,
              type: newSchedule.type,
              __typename: newSchedule.__typename,
            }
            store.writeQuery({
              query: MY_CALENDARS_LIST_QUERY,
              data: {
                myCalendars,
              },
            })
            const { schedule } = store.readQuery({
              query: SCHEDULE_QUERY,
              variables: {
                where: {
                  id: this.selectedSchedule?.id,
                },
              },
            })
            schedule.performanceGoals = newSchedule.performanceGoals
            store.writeQuery({
              query: SCHEDULE_QUERY,
              variables: {
                where: {
                  id: this.selectedSchedule?.id,
                },
              },
              data: {
                schedule,
              },
            })
          },
          refetchQueries: [
            {
              query: CALENDAR_SHOW_QUERY,
              variables: {
                where: {
                  id: this.calendar.id,
                },
              },
            },
          ],
        })
        this.$emit('updated', res)
      } catch (e) {
        //
      }
    },
  },
  apollo: {
    assetsByRecipient: {
      query: ASSETS_BY_RECIPIENT_QUERY,
      variables() {
        return {
          where: {
            recipientId: this.calendar.ownerId,
            recipientType: this.calendar.ownerType,
          },
        }
      },
      skip() {
        return !this.calendar?.ownerType || !this.calendar?.ownerId
      },
    },
    calendar: {
      query: CALENDAR_QUERY,
      variables() {
        return {
          where: {
            id: this.calendarId,
          },
        }
      },
      skip() {
        return !this.calendarId
      },
    },
    schedule: {
      query: SCHEDULE_QUERY,
      variables() {
        return {
          where: {
            id: this.scheduleId,
          },
        }
      },
      skip() {
        return !this.scheduleId
      },
    },
  },
}
</script>
